import React, { useState } from "react";
import styled from "styled-components";
import { OptionContainer } from "./TopBarMenu";
import { BiGift } from "react-icons/bi";
import { useLoggedInUserContext } from "../../../context/LoggedInUserContextProvider";
import { toast } from "react-toastify";
import FreeCreditsModal from "../../Modals/TopMenuModals/FreeCreditsModal/FreeCreditsModal";
import { IoAlertOutline } from "react-icons/io5";
import { getTimeUntilNextFreeCredits } from "./helpers";
import { useGeneralContext } from "../../../context/GeneralContextProvider";

const ClaimFreeCredits: React.FC = () => {
  const { loggedInUser } = useLoggedInUserContext();
  const { setShowConfirmEmailModal } = useGeneralContext();
  const [showModal, setShowModal] = useState(false);

  const handleClaimFreeCredits = () => {
    if (!loggedInUser?.verifiedEmail) {
      toast.error("Please verify your email first");
      setShowConfirmEmailModal(true);
      return;
    }
    setShowModal(true);
  };

  if (!loggedInUser) return null;
  const timeUntil = getTimeUntilNextFreeCredits(loggedInUser);
  const isAllowedToClaim = timeUntil === "00:00:00";

  return (
    <>
      <OptionContainer onClick={handleClaimFreeCredits}>
        <Container>
          <ClaimCreditsButton>
            <BiGift size={18} color={"green"} /> Claim Free Credits
            {isAllowedToClaim && <IoAlertOutline size={20} color={"red"} />}
          </ClaimCreditsButton>
        </Container>
      </OptionContainer>
      <FreeCreditsModal showModal={showModal} setShowModal={setShowModal} />
    </>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;

  font-weight: 400;
`;

const ClaimCreditsButton = styled.button`
  display: flex;
  align-items: center;

  background-color: transparent;
  border: none;

  font-size: 14px;

  gap: 8px;
`;

export default ClaimFreeCredits;

import { SpeedModeType } from '../types';

// Extend the Window interface to include Plausible's `plausible` function
declare global {
  interface Window {
    plausible: (
      eventName: string,
      options?: { props?: Record<string, any>; callback?: () => void },
    ) => void;
  }
}

/**
 * Tracks events using Plausible analytics.
 * @param eventName The name of the event to track.
 */
const trackEvent = (eventName: string) => {
  if (window.plausible) {
    window.plausible(eventName);
  }
};

export const trackClickGenerateOnLanding = () => {
  trackEvent('click-generate-on-landing');
};

export const trackClickCreateVariations = () => {
  trackEvent('click-create-variations');
};

export const trackUserIsAskedToVerifyEmail = () => {
  trackEvent('user-is-asked-to-verify-email');
};

export const trackUserWithMoreThan100CreditsFetched = () => {
  trackEvent('user-with-more-than-100-credits-fetched');
};

export const trackGoldUserInfoFetched = () => {
  trackEvent('user-with-gold-status-fetched');
};

export const trackClickedNoThanksOnHintToRegister = () => {
  trackEvent('clicked-no-thanks-on-hint-to-register');
};

// NOT USED ANYMORE
export const trackClaimedCredits = () => {
  trackEvent('claimed-credits');
};

export const trackUserOpensAnImage = () => {
  trackEvent('user-opens-an-image');
};

export const trackPostedImage = () => {
  trackEvent('posted-image');
};

export const trackClickedEditStyle = () => {
  trackEvent('clicked-edit-style');
};

export const trackStartedImageGeneration = (
  isLoggedIn: boolean,
  speedMode: SpeedModeType,
  isUserGold: boolean,
) => {
  if (isUserGold) {
    trackEvent(`started-image-generation-gold`);
    return;
  }
  if (isLoggedIn) {
    trackEvent(`started-image-generation-${speedMode}`);
  }
  trackEvent('started-free-user-slow-generation');
};

export const trackFinishedFreeUserSlowGeneration = () => {
  trackEvent('finished-free-user-slow-generation');
};

export const trackFinishedNonLoggedInSlowGeneration = () => {
  trackEvent('finished-non-logged-in-slow-generation');
};

/*
THIS IS ALREADY IN BELOW; HERE JUST FOR EASE OF THINKING
export const trackFirstTimeUserBeginsImageGeneration = () => {
  trackEvent('first-time-user-begins-image-generation');
};
*/

export const trackFirstTimeUserFinishesImageGeneration = () => {
  trackEvent('first-time-user-finishes-image-generation');
};

export const trackGoldUserFinishesGeneration = () => {
  trackEvent('gold-user-finishes-generation');
};

//total started
export const trackImageGenerationStarted = () => {
  trackEvent('image-generation-started');
};

// total finished
//trackImageGenerationFinished
export const trackImageGenerationFinished = () => {
  trackEvent('image-generation-finished');
};

// finished-image-generation-normal
// finished-image-generation-fast
// finished-image-generation-turbo
export const trackFinishedImageGeneration = (speedMode: SpeedModeType) => {
  trackEvent(`finished-image-generation-${speedMode}`);
};

export const trackImageGenerationFailedImmediately = () => {
  trackEvent('image-generation-failed-immediately');
};

export const trackImageGenerationFailedWithNoImageData = () => {
  trackEvent('image-generation-failed-with-no-image-data');
};

export const trackNavigatedToFeedFromLandingPage = () => {
  trackEvent('navigated-to-feed-from-landing-page');
};

export const trackNavigatedToAppUsingTopBar = () => {
  trackEvent('navigated-to-app-using-top-bar');
};

export const trackGeneratedMultipleImagesInOneSession = () => {
  trackEvent('generated-multiple-images-in-one-session');
};

// New ones to track main user flows

// Landing page to app flow

export const trackUserOpensTheSite = () => {
  trackEvent('user-opens-the-site');
};

export const trackNavigatedToLoginFromLandingPage = () => {
  trackEvent('navigated-to-login-from-landing-page');
};

export const trackNavigatedToAppUsingCTA = () => {
  trackEvent('navigated-to-app-using-cta');
};

export const trackFirstTimeUserDetected = () => {
  trackEvent('first-time-user-detected');
};

export const trackFirstTimeUserBeginsImageGeneration = () => {
  trackEvent('first-time-user-begins-image-generation');
};

export const trackFirstTimeUserOpensAnImage = () => {
  trackEvent('first-time-user-opens-an-image');
};

export const trackFirstTimeUserGeneratedMultipleImagesInOneSession = () => {
  trackEvent('first-time-user-generated-multiple-images-in-one-session');
};

// signup flow

export const trackUserIsAskedToSignup = () => {
  trackEvent('user-is-asked-to-signup');
};

export const trackNewSignup = () => {
  trackEvent('signup');
};

export const trackUserVerifiedEmail = () => {
  trackEvent('user-verified-email');
};

export const trackFirstTimeCreditsClaimed = () => {
  trackEvent('first-time-credits-claimed');
};

//

//engaged user
export const trackUserHasGeneratedMoreThan9ImagesInOneSession = () => {
  trackEvent('generated-more-than-9-images-in-one-session');
};

// Returning user
export const trackUserHasLoggedIn = () => {
  trackEvent('login');
};

// OR
export const trackUserDataHasBeenFetched = () => {
  trackEvent('user-data-has-been-fetched');
};

export const trackDailyCreditsClaimed = () => {
  trackEvent('daily-credits-claimed');
};

// Very engaged user
export const trackUserHasGeneratedMoreThan20ImagesInOneSession = () => {
  trackEvent('generated-more-than-20-images-in-one-session');
};

// Payment
export const trackOpenedSubscriptionModal = () => {
  trackEvent('opened-subscription-modal');
};

export const trackClickedBuySubscription = () => {
  trackEvent('clicked-buy-subscription');
};

export const trackOpenedCreditsModal = () => {
  trackEvent('opened-credits-modal');
};

export const trackClickedBuyCredits = () => {
  trackEvent('clicked-buy-credits');
};

export const trackExampleTagIsChosen = () => {
  trackEvent('example-tag-is-chosen');
};

export const trackExampleTagIsChosenFirstTimeUser = () => {
  trackEvent('example-tag-is-chosen-first-time-user');
};


////
export const trackNewApiUsed = () => {
  trackEvent('new-api-used');
}

export const trackFinishedImageGenerationNewAPI = () => {
  trackEvent('finished-image-generation-new-api');
}

export const trackNewGenerationOnMagicMode = () => {
  trackEvent('new-generation-on-magic-mode');
}
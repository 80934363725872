import FingerprintJS from "@fingerprintjs/fingerprintjs";

const initializeFingerprint = async () => {
  try {
    const fp = await FingerprintJS.load();
    const result = await fp.get();

    // This is your visitor's fingerprint
    const visitorId = result.visitorId;
    return visitorId;
  } catch (error) {
    console.error("Error generating fingerprint:", error);
    return null;
  }
};

export default initializeFingerprint;

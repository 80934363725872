import styled from "styled-components";
import AmountToGenerateSelector from "./ButtonsBelowImage/AmountToGenerateSelector";
import GenerateButton from "./ButtonsBelowImage/GenerateButton";
import SpeedModeSelector from "./ButtonsBelowImage/SpeedModeSelector";
import { EngineType, SpeedModeType } from "../../types";
import { fadeIn } from "../../components/ImageStuff/animations";

interface P {
  speedMode: SpeedModeType;
  setSpeedMode: (speedMode: SpeedModeType) => void;
  amountToGenerate: number;
  setAmountToGenerate: (amountToGenerate: number) => void;
  handleSendPrompt: () => void;
  engine: EngineType;
}

const MainButtons = ({
  speedMode,
  setSpeedMode,
  amountToGenerate,
  setAmountToGenerate,
  handleSendPrompt,
  engine,
}: P) => (
  <MainButtonsContainer>
    <SpeedModeSelector speedMode={speedMode} setSpeedMode={setSpeedMode} />
    <AmountToGenerateSelector
      amountToGenerate={amountToGenerate}
      setAmountToGenerate={setAmountToGenerate}
    />
    <GenerateButton
      handleSendPrompt={handleSendPrompt}
      speedMode={speedMode}
      amountToGenerate={amountToGenerate}
      engine={engine}
    />
  </MainButtonsContainer>
);

const MainButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-evenly;

  width: 95%;
  padding-left: 16px;
  margin-bottom: 24px;

  // hack so when going up looks better
  animation: ${fadeIn} 3s ease-in-out;
`;

export default MainButtons;

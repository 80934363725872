import styled from "styled-components";
import StyledSelectOriginal from "../../../components/common/StyledSelect";

export const ContainerForSelect = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  margin-right: 16px;
  margin-top: 9px;

  width: 100%;
  max-width: 70px;

  @media (min-width: 568px) {
    max-width: 100px;
  }
`;

export const StyledSelectForBelowImage = styled(StyledSelectOriginal)`
  width: 105%;
  height: 38px;
  font-size: 12px;

  text-align-last: center; /* This will center the text in the select itself */

  transition: border-color 0.2s;

  option {
    /* This will attempt to center the text in the dropdown */
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
